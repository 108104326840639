import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { CommonService } from "../common/common.service";

@Injectable()
export class AuthGuardMain implements CanActivate {
  constructor(private router: Router, private commonService: CommonService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.commonService.isAuthenticated()) {
      let userData = this.commonService.getUser();
      if (route.data.role && route.data.role.indexOf(userData.role) == -1) {
        this.router.navigate(["/main/dashboard"]);
        return false;
      } else {
        return true;
      }
    }
    this.router.navigate(["/auth/login"]);
    return false;
  }
}
