import { Injectable } from '@angular/core';
import { NetworkService } from "../../common/network.service";
@Injectable({
  providedIn: 'root'
})
export class CarnivalService {

  constructor(private _networkService: NetworkService) { }

  uploadImage(image: File) {
    const formData = new FormData();
    formData.append("image", image);
    return this._networkService.uploadImages("api/s3upload/image-upload-sync", formData, null, "bearer");
  }

  postCarnivalApi(body: any) {
    return this._networkService.post("api/carnivals", body, null, "bearer")
  }
  getCarnivalListApi(query: any) {
    return this._networkService.get("api/carnivals?" + query, null, null, "bearer");
  }

  putCarnivalApi(body: any) {
    return this._networkService.put("api/carnivals", body, null, "bearer")
  }

  deleteCarnivalApi(query: any) {
    return this._networkService.delete("api/carnivals/" + query, null, null, "bearer");
  }

  getTimeZone(query: any) {
    return this._networkService.google('https://maps.googleapis.com/maps/api/timezone/json?location=' + query, null, null, null);
  }

  getEventsListApi(query: any) {
    return this._networkService.get("api/events/list?" + query, null, null, "bearer");
  }

  getPartnerListApi(query: any) {
    return this._networkService.get("api/partners/list?" + query, null, null, "bearer");
  }

  postPartner(body: any) {
    return this._networkService.post("api/partners", body, null, "bearer")
  }

  putPartner(body: any) {
    return this._networkService.put("api/partners", body, null, "bearer")
  }

  deletePartner(query: any) {
    return this._networkService.delete("api/partners/" + query, null, null, "bearer");
  }

  getEventsReviewApi(query: any) {
    return this._networkService.get("api/events/reviews?" + query, null, null, "bearer");
  }
  deleteReviewApi(query: any) {
    return this._networkService.delete("api/events/reviews/" + query, null, null, "bearer");
  }


  deleteEventApi(query: any) {
    return this._networkService.delete("api/events/" + query, null, null, "bearer");
  }

  getServiceListApi(query: any) {
    return this._networkService.get("api/services/list" + query, null, null, "bearer");
  }

  deleteServiceApi(query: any) {
    return this._networkService.delete("api/services/" + query, null, null, "bearer");
  }

  // CONTINENT APIS
  getContinentListApi() {
    return this._networkService.get("api/continents?", null, null, "bearer");
  }
  putContinentApi(body: any) {
    return this._networkService.put("api/continents", body, null, "bearer")
  }

  // POPUPS

  getPopUpListApi() {
    return this._networkService.get("api/mobilePopups", null, null, "bearer");
  }

  putPopUpApi(body: any) {
    return this._networkService.put("api/mobilePopups", body, null, "bearer")
  }
}
