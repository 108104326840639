import { Injectable } from "@angular/core";
import { NetworkService } from "../../common/network.service";

@Injectable()
export class DashboardService {
  constructor(private _networkService: NetworkService) { }

  getDashboardCount() {
    return this._networkService.get("api/dashboard", null, null, "bearer");
  }

  getUserMonthlyApi() {
    return this._networkService.get(
      "api/dashboard/monthlyUserData",
      null,
      null,
      "bearer"
    );
  }

  getUserPieChartDataApi() {
    return this._networkService.get("api/dashboard/userData")
  }

  getContestPieChartDataApi() {
    return this._networkService.get("api/dashboard/contestData")
  }

  getCarnivalPieChartDataApi() {
    return this._networkService.get("api/dashboard/carnivalData")
  }

  getReportPieChartDataApi() {
    return this._networkService.get("api/dashboard/reportData")
  }

  getTransactionsLineChartDataApi() {
    return this._networkService.get("api/dashboard/monthlyRevenuwData")
  }

  getMonthlyCallsApi() {
    return this._networkService.get(
      "api/dashboard/monthlyCallData",
      null,
      null,
      "bearer"
    );
  }

}
