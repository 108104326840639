import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { CommonService } from "../common/common.service";
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/internal/operators/catchError';
import Swal from "sweetalert2";

@Injectable()
export class NetworkService {
  private backendApiURL = environment.backendApiURL;

  constructor(
    private http: HttpClient,
    private _commonService: CommonService, ) { }

  get(url: any, param?: any, head?: any, auth?: any): Observable<any> {
    return this.http.get(this.backendApiURL + url, this._commonService.getToken(auth)).pipe(
      catchError(this.handleError.bind(this)));
  }

  google(url: any, param?: any, head?: any, auth?: any): Observable<any> {
    return this.http.get(url).pipe(
      catchError(this.handleError.bind(this)));
  }

  login(url: any, param?: any, head?: any, auth?: any): Observable<any> {
    return this.http.post(this.backendApiURL + url, param, {
      observe: "response"
    }).pipe(
      catchError(this.handleError.bind(this)));
  }

  post(url: any, param?: any, head?: any, auth?: any): Observable<any> {
    return this.http.post(this.backendApiURL + url, param, this._commonService.getToken(auth)).pipe(
      catchError(this.handleError.bind(this)));;
  }

  delete(url: any, param?: any, head?: any, auth?: any): Observable<any> {
    return this.http.delete(this.backendApiURL + url, this._commonService.getToken(auth)).pipe(
      catchError(this.handleError.bind(this)));
  }

  put(url: any, param?: any, head?: any, auth?: any): Observable<any> {
    return this.http.put(this.backendApiURL + url, param, this._commonService.getToken(auth)).pipe(
      catchError(this.handleError.bind(this)));;
  }
  uploadImages(url: any, param?: any, head?: any, auth?: any): Observable<any> {
    return this.http.post(this.backendApiURL + url, param);
  }
  handleError(error: HttpErrorResponse) {
    if (error.status == 401) {
      Swal.fire("Error", error.error.data, "error");
      this._commonService.logOut();
    } else if (error.status == 404) {
      Swal.fire("Error", error.statusText, "error");
    } else {
      Swal.fire("Error", error.error.data, "error");
    }
    return throwError(error)
  }

}
