import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CarnivalService } from '../carnival-management/carnival.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-view-service',
  templateUrl: './view-service.component.html',
  styleUrls: ['./view-service.component.scss']
})
export class ViewServiceComponent implements OnInit {
  constructor(private spinner:NgxSpinnerService,private carnivalService:CarnivalService) { }
  pageLimit:any
  loadingIndicator:any
  reorderable:any
  ngOnInit() {
    this.getServiceList();
  }
  serviceList:any;
  getServiceList() {
    let query = '';
    // var queryValue = this.queryParams();
    this.spinner.show();
  this.carnivalService.getServiceListApi(query).subscribe(
    res => {
      this.spinner.hide();
      if ((res["message"] = "Success")) {
        this.serviceList = res["data"]["serviceList"];
      } else {
        this.serviceList = [];
      }
      this.spinner.hide();
    },
    err => {
      this.spinner.hide();
    }
  );
  }   searchBusinessFilters() {
    this.getServiceList();
  }

  deleteServiceAction(title,serviceId) {
      Swal.fire({
        title: "You Want to delete: " + title,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          let query = serviceId
          
          this.spinner.show();
          this.carnivalService.deleteServiceApi(query).subscribe(
            res => {
              this.spinner.hide();
              if (res["message"] == "Success") {
                Swal.fire("Success", "Service deleted successfully!", "success");
              } else {
                Swal.fire("Error", res.data, "error");
              }
              this.spinner.hide();
              this.getServiceList();
            },
            err => {
              this.spinner.hide();
            }
          );
        }
      });
    }
  
    showImageModal(image: any) {
      if (image) {
        Swal.fire({
          title: "Cover Image",
          imageUrl: image,
          imageHeight: 250,
          imageWidth: 250,
          customClass: {
            title: "title-class11"
          }
        });
      }
    }

    // onReset() {
    //   this.spinner.show();
    //   setTimeout(() => {
    //     /** spinner ends after 5 seconds */
    //     this.spinner.hide();
    //   }, 2000);
    //     (this.searchStartDate = ""),
    //     (this.searchEndDate = "");
    //   this.getCarnivalList();
    // }




}

