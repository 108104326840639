import { Component, OnInit } from '@angular/core';
import {NgxSpinnerService} from "ngx-spinner";
import {Router} from '@angular/router'; 
import Swal from 'sweetalert2';
import { CarnivalService } from '../carnival-management/carnival.service';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-continents',
  templateUrl: './continents.component.html',
  styleUrls: ['./continents.component.scss']
})
export class ContinentsComponent implements OnInit {
  continentList = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  pageLimit: number = 10;
  searchStartDate;
  searchEndDate;
  selectedFile: any;
  coverImageUrl: string;
  constructor(private router: Router,private _snackBar: MatSnackBar,private carnivalService:CarnivalService,private spinner:NgxSpinnerService) { }

  ngOnInit() {
    this.getContinentList()
  }

  
    getContinentList() {
      this.spinner.show();
    this.carnivalService.getContinentListApi().subscribe(
      res => {
        this.spinner.hide();
        if ((res["message"] = "Success")) {
          this.continentList = res["data"]["continentList"];
        } else {
          this.continentList = [];
        }
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
      }
    );
    }

    searchBusinessFilters() {
      this.getContinentList();
    }

        
    showImageModal(image: any) {
      if (image) {
        Swal.fire({
          title: "Image",
          imageUrl: image,
          imageHeight: 250,
          imageWidth: 250,
          customClass: {
            title: "title-class11"
          }
        });
      }
    }

    row:any
  async addCoverPic(value: any) {
    this.row = value
    const { value: file } = await Swal.fire({
      title: 'Select image',
      input: 'file',
      inputAttributes: {
        'accept': 'image/*',
        'aria-label': 'Upload Cover Image'
      }
    })
    if (file) {
      // if(file['size'] <5000) {
      //   Swal.fire("size is very less")
      //   return
      // }
      this.selectedFile = file
      this.onFileChanged(file, "cover")
    }
  }

  onFileChanged(event, value) {
    this.spinner.show();
    this.carnivalService.uploadImage(this.selectedFile).subscribe(res => {
      if (res["message"] === "Success") {
        this.spinner.hide();
          this.coverImageUrl = res.data.url;
          this.selectedFile = null;
          this.submitForm();
      } else {
        Swal.fire({
          title: "Error",
          text: res.message
        });
      }
    }, err => {
    });
  }

  submitForm() {

    let reqObj = {
      continentId:this.row.continentId,
      image:this.coverImageUrl
    };  
      this.spinner.show();
      this.carnivalService.putContinentApi(reqObj).subscribe (
        data => {
          this.spinner.hide();
          if (data["message"] == "Success") {
            this.getContinentList();
            this._snackBar.open("Continent edited successfully", "", {
              duration: 2000,
              horizontalPosition: "right",
              verticalPosition: "top",
              panelClass: ["success"],
            });          
            this.coverImageUrl = "";
          } else {
            Swal.fire("Error", data.data, "error");
          }
        },
        err => {
          this.spinner.hide();
        }
      );
  }


descriptionSwal(desc) {
  Swal.fire(desc)
}

}
