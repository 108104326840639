import { Injectable } from "@angular/core";
import { NetworkService } from "../common/network.service";

@Injectable()
export class AuthServices {
  constructor(private networkService: NetworkService) { 
    
  }

  validateLogin(req: any) {
    return this.networkService.login("api/admins/login", req, null, null);
  }
  validateClubLogin(req: any) {
    return this.networkService.login("api/clubroles/login", req, null, null);
  }

  // forgetPassword(req: any) {
  //   return this.networkService.post("api/auth/password/forgot", req, null, null);
  // }
  // resetpassword(req: any) {
  //   return this.networkService.post("api/auth/verify/password/forgot", req, null, null);
  // }
}
