import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// SERVICES
import { SharedModule } from "../common/shared.modules";
import { SettingService } from "./setting-management/setting.service"
import { DashboardService } from "./dashboard/dashboard.service";
import { MatButtonModule } from '@angular/material/button'

// USER
import { routing } from "./main.routing";
import { MainComponent } from "./main.component";
import { HeaderComponent } from "./header/header.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { SettingManagementComponent } from "./setting-management/setting-management.component"
import { UserManagementModule } from './user-management/user-management.module';
import { AgmCoreModule } from '@agm/core';
import {AppConfig} from '../common/config';
import { ViewEventComponent } from './view-event/view-event.component';
import { ViewServiceComponent } from './view-service/view-service.component';
import { EventReviewComponent } from './event-review/event-review.component';
import { ContinentsComponent } from './continents/continents.component';
import { PopupComponent } from './popup/popup.component';
import { PartnerComponent } from "./partner/partner.component";
import { AddPartnerComponent } from "./partner/add-partner/add-partner.component";
@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    routing,
    UserManagementModule,
    MatButtonModule,
    AgmCoreModule.forRoot({
      // please get your own API key here:
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
      //apiKey: 'AIzaSyAsVrHxXGITDH-g6ozMNeoEAtiFuhGulwI'
      // apiKey: 'AIzaSyC2elVY4MinU6aq8Rid0OctUKoczgCh5Zc',
      apiKey:AppConfig.apikey,
      libraries: ['places', 'geometry']
    })
  ],
  declarations: [
    MainComponent,
    HeaderComponent,
    SidebarComponent,
    SettingManagementComponent,
    ViewEventComponent,
    ViewServiceComponent,
    EventReviewComponent,
    ContinentsComponent,
    PopupComponent,
    PartnerComponent,
    AddPartnerComponent
  ],
  providers: [
    DashboardService,
    SettingService
  ]
})
export class MainModule { }
