import { Routes, RouterModule } from "@angular/router";
import { ModuleWithProviders } from "@angular/compiler/src/core";
import { UserManagementComponent } from "./user-management.component";
import { ViewUserComponent } from "./view-user/view-user.component";


export const routes: Routes = [
    {
        path: '', component: UserManagementComponent,
        children: [
            { path: '', redirectTo: '/main/user-management/view-user', pathMatch: 'full' },
            { path: "view-user", component: ViewUserComponent },

        ]
    },
];



export const routing: ModuleWithProviders = RouterModule.forChild(routes);
