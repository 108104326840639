import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CarnivalService } from '../carnival-management/carnival.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {
  pageLimit: any
  loadingIndicator: any
  reorderable: any
  partnerList: any;

  constructor(private spinner: NgxSpinnerService, private carnivalService: CarnivalService, private router: Router) { }

  ngOnInit() {
    this.getPartnerList();
  }

  addPartnerRouting() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
      this.router.navigateByUrl("/main/partner/add-partner");
    }, 2000)
  }

  getPartnerList() {
    let query = '';
    // var queryValue = this.queryParams();
    this.spinner.show();
    this.carnivalService.getPartnerListApi(query).subscribe(
      res => {
        this.spinner.hide();
        if ((res["message"] = "Success")) {
          this.partnerList = res["data"]["partnerList"];
        } else {
          this.partnerList = [];
        }
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
      }
    );
  }

  searchBusinessFilters() {
    this.getPartnerList();
  }

  deleteEventAction(title, partnerId) {
    Swal.fire({
      title: "You Want to delete: " + title,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.value) {
        let query = partnerId;
        this.spinner.show();

        this.carnivalService.deletePartner(query).subscribe(res => {
          this.spinner.hide();
          if (res["message"] == "Success") {
            Swal.fire("Success", "Event deleted successfully!", "success");
          } else {
            Swal.fire("Error", res.data, "error");
          }
          this.spinner.hide();
          this.getPartnerList();
        }, err => {
          this.spinner.hide();
        });
      }
    });
  }

  showImageModal(image: any) {
    if (image) {
      Swal.fire({
        title: "Cover Image",
        imageUrl: image,
        imageHeight: 250,
        imageWidth: 250,
        customClass: {
          title: "title-class11"
        }
      });
    }
  }

  // onReset() {
  //   this.spinner.show();
  //   setTimeout(() => {
  //     /** spinner ends after 5 seconds */
  //     this.spinner.hide();
  //   }, 2000);
  //     (this.searchStartDate = ""),
  //     (this.searchEndDate = "");
  //   this.getCarnivalList();
  // }




}
