import { Component, OnInit, ViewChild } from "@angular/core";
  import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
  import { FormBuilder } from "@angular/forms";
  import { NgxSpinnerService } from "ngx-spinner";
  import { CommonService } from "src/app/common/common.service";
  import { SettingService } from ".././setting-management/setting.service";
  import Swal from "sweetalert2";
  import { HttpResponse } from "@angular/common/http";
  import { query } from "@angular/animations";

@Component({
  selector: "app-setting-management",
  templateUrl: "./setting-management.component.html",
  styleUrls: ["./setting-management.component.scss"]
})
export class SettingManagementComponent implements OnInit {
  name = "ng2-ckeditor";
    ckeConfig: any;
    mycontent: string;
    log: string = ""; 

    @ViewChild("myckeditor1", null) ckeditor1: any;
    @ViewChild("myckeditor2", null) ckeditor2: any;
    @ViewChild("myckeditor3", null) ckeditor3: any;
  
    isEditStep: string = "false";
    isEditAwarness: string = "false";
    awarenessList: [];
    stepList: [];
    id: string = "";
    type: number = 1;
    textPrivacy: string = "";
    textTerm: string = "";
    textSupport: string = "";
    textAbout: string = "";
  
    constructor(
      private fb: FormBuilder,
      private spinner: NgxSpinnerService,
      private commonService: CommonService,
      private SettingService: SettingService,
    ) {}
  
    ngOnInit() {
      this.ckeConfig = {
        allowedContent: false,
        extraPlugins: "divarea",
        forcePasteAsPlainText: true
      };
      this.getprivacyPolicy();
      this.getTermNCondtion();
      this.supportCenter();
    }
  
    getprivacyPolicy() {
      let query = "?status=privacyPolicy";
      this.spinner.show();
      this.SettingService.getSetting(query).subscribe(
        res => {
          this.spinner.hide();
          if ((res["message"] = "Success")) {
            this.textPrivacy = res.data;
          }
        },
        err => {
          this.spinner.hide();
        }
      );
    }
    supportCenter() {
      let query = "?status=support";
      this.spinner.show();
      this.SettingService.getSetting(query).subscribe(
        res => {
          this.spinner.hide();
          if ((res["message"] = "Success")) {
            this.textSupport = res.data;
          }
        },
        err => {
          this.spinner.hide();
        }
      );
    }

    getTermNCondtion() {
      this.spinner.show();
      let query = "?status=termsNConditions";
      this.SettingService.getSetting(query).subscribe(
        res => {
          this.spinner.hide();
          if ((res["message"] = "Success")) {
            this.textTerm = res.data;
          } else {
          }
        },
        err => {
          this.spinner.hide();
        }
      );
    }
  
    SubmitPrivacy() {
      let value = {
        text: this.textPrivacy,
        status: "privacyPolicy"
      };
      this.SettingService.saveSetting(value).subscribe(res => {
        if ((res["message"] = "Success")) {
          this.getprivacyPolicy();
          Swal.fire("Success", "Saved successfully!", "success");
        } else {
        }
      });
    }
    SubmitSupportCenter() {
      let req = {
        text: this.textSupport,
        status: "support"
      };
      this.SettingService.saveSetting(req).subscribe(res => {
        if ((res["message"] = "Success")) {
          Swal.fire("Success", "Saved successfully!", "success");
          this.supportCenter();
        } else {
        }
      });
    }
    SubmitContact() {
      let value = {
        text: this.textTerm,
        status: "termsNConditions"
      };
      this.SettingService.saveSetting(value).subscribe(res => {
        if ((res["message"] = "Success")) {
          Swal.fire("Success", "Saved successfully!", "success");
          this.getTermNCondtion();
        } else {
        }
      });
    }
  }
  