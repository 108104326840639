import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CarnivalService } from '../carnival-management/carnival.service';
import Swal from 'sweetalert2';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { BlogService } from '../blog/blog.service';
@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  constructor(private spinner:NgxSpinnerService,private carnivalService:CarnivalService,private blogService:BlogService) { }
  pageLimit:any
  loadingIndicator:any
  reorderable:any
  selectedFile: any;
  ngOnInit() {
    this.getPopUpList();
  }
  serviceList:any;
  getPopUpList() {
    this.spinner.show();
  this.carnivalService.getPopUpListApi().subscribe(
    res => {
      this.spinner.hide();
      if ((res["message"] = "Success")) {
        this.serviceList = res["data"]["mobilePopupList"];
      } else {
        this.serviceList = [];
      }
      this.spinner.hide();
    },
    err => {
      this.spinner.hide();
    }
  );
  } 

  searchBusinessFilters() {
    this.getPopUpList();
  }

  deleteServiceAction(title,serviceId) {
      Swal.fire({
        title: "You Want to delete: " + title,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          let query = serviceId
          
          this.spinner.show();
          this.carnivalService.deleteServiceApi(query).subscribe(
            res => {
              this.spinner.hide();
              if (res["message"] == "Success") {
                Swal.fire("Success", "Service deleted successfully!", "success");
              } else {
                Swal.fire("Error", res.data, "error");
              }
              this.spinner.hide();
              this.getPopUpList();
            },
            err => {
              this.spinner.hide();
            }
          );
        }
      });
    }
  
    showImageModal(image: any) {
      if (image) {
        Swal.fire({
          title: "Image",
          imageUrl: image,
          imageHeight: 250,
          imageWidth: 250,
          customClass: {
            title: "title-class11"
          }
        });
      }
    }

  /// Image upload Start
  imageChangedEvent: any = "";
  croppedImage: any = "";
  isUploading: boolean = false;
  mobileId:""
  fileChangeEvent(event: any,id): void {
    this.spinner.show();
   this.spinner.show();
   this.mobileId= id
   let file, img;
   if (
     (file = event.target.files[0]) &&
     (file.type === "image/png" || file.type === "image/jpeg")
   ) {
     img = new Image();
     this.imageChangedEvent = event;
   } else {
     Swal.fire({
       icon: "error",
       html: "Unsupported File Type. Only jpeg and png is allowed!",
     });
   }
   this.spinner.hide();
 }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
 
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

   //IMAGE UPLOAD START
 b64toBlob(dataURI) {
  var byteString = atob(dataURI.split(",")[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: "image/jpeg" });
}

  save(): void {
    console.log(this.croppedImage,"CROPPED")
    const blob = this.b64toBlob(this.croppedImage);
    this.imageChangedEvent = false;
    // this.isLoading = true;
    this.spinner.show();
    this.blogService.uploadImage(blob).subscribe(
      (res) => {
        // this.isLoading = false;
        this.spinner.hide();
        if (res["message"] == "Success") {
          this.putPopup(res.data.url);
          // this.profilePic = res["data"]["url"];
          // this.blogForm.controls['image'].setValue(this.profilePic)
          // this.isLoading = false;
          this.spinner.hide();
        }
      },
      (err) => {
        // this.isLoading = false;
        this.spinner.hide();
      }
    );
  }
 
  cancel(): void {
    this.croppedImage = null;
    this.imageChangedEvent = false;
    this.isUploading = false;
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.isUploading = true;
    }, 1000);
  }

  putPopup(url) {
    let req= {
      imageUrl:url,
      mobilePopupId:this.mobileId
    }
    this.spinner.show();
  this.carnivalService.putPopUpApi(req).subscribe(
    res => {
      this.spinner.hide();
      if ((res["message"] = "Success")) {
        this.getPopUpList();
        Swal.fire("Success", "Image Changed Successfully!", "success");
      } else {
        this.serviceList = [];
      }
      this.spinner.hide();
    },
    err => {
      this.spinner.hide();
    }
  );
  }

}

