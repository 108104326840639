import { Injectable } from '@angular/core';
import { NetworkService } from 'src/app/common/network.service';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private networkservice:NetworkService) { }

  getblogApi(query:any) {
    return this.networkservice.get("api/blogs/list?"+query,null,null,'bearer')
  }
  saveBlog(body:any) {
    return this.networkservice.post("api/blogs",body,null,'bearer')
  }
  updateBlog(body:any) {
    return this.networkservice.put("api/blog",body,null,'bearer')
  }
  deleteBlogApi(blogId:any) {
    return this.networkservice.delete("api/blogs/"+blogId,null,null,'bearer')
  }
  uploadImage(image: any) {
    const formData = new FormData();
    formData.append("image", image);
    return this.networkservice.uploadImages("api/s3upload/image-upload", formData, null, "bearer");
  }
  }



