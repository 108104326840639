import { Routes, RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { MainModule } from "./main/main.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NetworkService } from "./common/network.service";
import { CommonService } from "./common/common.service";
import { HttpClientModule } from "@angular/common/http";
import { ConfirmationComponent } from "./common/components/confirmation/confirmation.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AuthModule } from "./auth/auth.module";
import { AuthGuardMain } from "./auth/auth-guard.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ThemeService } from 'ng2-charts';
const routes: Routes = [
  { path: "", redirectTo: "auth/login", pathMatch: "full" }
];

@NgModule({
  declarations: [AppComponent, ConfirmationComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MainModule,
    RouterModule,
    HttpClientModule,
    NgbModule,
    AuthModule,
    RouterModule.forRoot(routes),
    BrowserAnimationsModule,
    NgxSpinnerModule
  ],
  providers: [NetworkService, CommonService, AuthGuardMain,ThemeService],
  bootstrap: [AppComponent],
  entryComponents: [ConfirmationComponent]
})
export class AppModule {}
