

export const environment = {
  production: false,
  // backendApiURL: "https://solomas.herokuapp.com/",
   backendApiURL: "https://api.solomasapp.com/",
  //backendApiURL: "https://apiuat.solomasapp.com/",
  translate: 'en' // english
};

