import { Routes, RouterModule } from "@angular/router";
import { MainComponent } from "./main.component";
import { ModuleWithProviders } from "@angular/core";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { SettingManagementComponent } from './setting-management/setting-management.component';
import { AuthGuardMain } from '../auth/auth-guard.service';
import { ViewEventComponent } from './view-event/view-event.component';
import { ViewServiceComponent } from './view-service/view-service.component';
import { EventReviewComponent } from './event-review/event-review.component';
import { ContinentsComponent } from './continents/continents.component';
import { PopupComponent } from './popup/popup.component';
import { PartnerComponent } from "./partner/partner.component";
import { AddPartnerComponent } from "./partner/add-partner/add-partner.component";

export const routes: Routes = [
  {
    path: "main",
    component: MainComponent,
    canActivate: [AuthGuardMain],
    children: [
      { path: "", redirectTo: "/main/dashboard", pathMatch: "full" },
      { path: 'continents', component: ContinentsComponent },
      { path: 'dashboard', loadChildren: 'src/app/main/dashboard/dashboard.module#DashboardModule' },
      { path: 'user-management', loadChildren: 'src/app/main/user-management/user-management.module#UserManagementModule' },
      { path: 'carnival-management', loadChildren: 'src/app/main/carnival-management/carnival-management.module#CarnivalManagementModule' },
      { path: 'reward-management', loadChildren: 'src/app/main/reward-management/reward-management.module#RewardManagementModule' },
      { path: 'group-management', loadChildren: 'src/app/main/group-management/group-management.module#GroupManagementModule' },
      { path: 'contests-management', loadChildren: 'src/app/main/contests/contests.module#ContestsModule' },
      { path: 'announcement-management', loadChildren: 'src/app/main/announcement/announcement.module#AnnouncementModule' },
      { path: 'event-announcement-management', loadChildren: 'src/app/main/event-announcement/event-announcement.module#EventAnnouncementModule' },
      { path: 'report-management', loadChildren: 'src/app/main/report-management/report-management.module#ReportManagementModule' },
      { path: 'category-management', loadChildren: 'src/app/main/category-management/category-management.module#CategoryManagementModule' },
      { path: 'blog-management', loadChildren: 'src/app/main/blog/blog.module#BlogModule' },
      { path: 'notification-management', loadChildren: 'src/app/main/notification/notification.module#NotificationModule' },
      { path: 'view-event', component: ViewEventComponent },
      { path: 'partner', component: PartnerComponent },
      { path: 'partner/add-partner', component: AddPartnerComponent },
      { path: 'partner/:id', component: AddPartnerComponent },
      { path: 'view-event-review', component: EventReviewComponent },
      { path: 'view-service', component: ViewServiceComponent },
      { path: 'popup-service', component: PopupComponent },
      {
        path: "setting-management",
        component: SettingManagementComponent,
      },
    ]
  }
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
