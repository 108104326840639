import { Injectable } from "@angular/core";
import { NetworkService } from 'src/app/common/network.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryMgmtService {
  constructor(private _networkService: NetworkService) { }

  getEventCategoryListApi(query) {
    return this._networkService.get("api/events/categories" + query, null, null, "bearer");
  }

  getPartnerCategoryListApi(query) {
    return this._networkService.get("api/partners/categories" + query, null, null, "bearer");
  }

  uploadImage(file: any) {
    const formData = new FormData();
    formData.append("file", file, "image.pdf");
    return this._networkService.uploadImages("api/s3upload/media-upload", formData, null, "bearer");
  }

  getSubCategoryListApi(query) {
    return this._networkService.get("api/services/categories" + query, null, null, "bearer");
  }

  postEventCategoryApi(body: any) {
    return this._networkService.post("api/events/categories", body, null, "bearer");
  }

  postPartnerCategoryApi(body: any) {
    return this._networkService.post("api/partners/categories", body, null, "bearer");
  }

  getPartnerById(id: any) {
    return this._networkService.get("api/partners/" + id, null, "bearer");
  }

  putEventCategoryApi(body: any) {
    return this._networkService.put("api/events/categories", body, null, "bearer");
  }

  putPartnerCategoryApi(body: any) {
    return this._networkService.put("api/partners/categories", body, null, "bearer");
  }

  postSubcategoryApi(body: any) {
    return this._networkService.post("api/services/categories", body, null, "bearer");
  }

  updateUserStatus(user: any) {
    return this._networkService.put("api/users/", user, null, "bearer");
  }

  deleteCategory(category: any) {
    return this._networkService.delete("api/events/categories/" + category, null, null, "bearer");
  }
  deletePartnerCategory(category: any) {
    return this._networkService.delete("api/partners/categories/" + category, null, null, "bearer");
  }
  deleteSubCategory(category: any) {
    return this._networkService.delete("api/services/categories/" + category, null, null, "bearer");
  }

  updateCategory(cat: any) {
    return this._networkService.put("api/categories", cat, null, "bearer");
  }

  updateSubcategory(cat: any) {
    return this._networkService.put("api/services/categories", cat, null, "bearer");
  }
}
