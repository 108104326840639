import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgSelectModule } from "@ng-select/ng-select";
import { MatTabsModule } from "@angular/material/tabs";
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatIconModule } from "@angular/material/icon";
import { AngularDateTimePickerModule } from "angular2-datetimepicker";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CKEditorModule } from 'ng2-ckeditor';
import { ChartsModule } from 'ng2-charts';
import { TagInputModule } from 'ngx-chips';
import {
  MatFormFieldModule,
  MatDatepickerModule,
  MatInputModule,
  MatOptionModule,
  MatSelectModule,
  MatRadioModule,
  MatTableModule, MatButtonModule, MatSnackBarModule
} from "@angular/material";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatNativeDateModule } from "@angular/material/core";
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
  imports: [
    CommonModule,
    ChartsModule,
    ImageCropperModule
  ],
  declarations: [],
  exports: [FormsModule, ReactiveFormsModule, NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    CKEditorModule,
    NgSelectModule,
    ImageCropperModule,
    NgxSpinnerModule,
    MatTabsModule,
    MatIconModule,
    AngularDateTimePickerModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatRadioModule,
    MatButtonModule,
    MatTableModule,
    MatSlideToggleModule,
    TagInputModule,
    DragDropModule,
    MatCheckboxModule,
    ChartsModule,
    MatSnackBarModule,
  ],
    

  providers: []
})
export class SharedModule { }