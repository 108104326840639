import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// USER
import { routing } from "./user.routing";
import { UserManagementComponent } from "./user-management.component";
import { ViewUserComponent } from "./view-user/view-user.component";

// SERVICES
import { UserMgmtService } from "./user-management.service";
import { SharedModule } from "..//../common/shared.modules";
@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        routing,
    ],
    declarations: [UserManagementComponent, ViewUserComponent
    ],
    providers: [UserMgmtService]
})
export class UserManagementModule { }
