import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import Swal from 'sweetalert2';
import { NgbModal, NgbModalRef, NgbModalOptions, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
import { MapsAPILoader } from '@agm/core';
// import { } from 'googlemaps';
import * as moment from "moment-timezone";
import { AppConfig } from '../../../common/config'
import { MatSnackBar } from '@angular/material';
import { CarnivalService } from '../../carnival-management/carnival.service';
import { CategoryMgmtService } from '../../category-management/category.service';
@Component({
  selector: 'app-add-partner',
  templateUrl: './add-partner.component.html',
  styleUrls: ['./add-partner.component.scss']
})
export class AddPartnerComponent implements OnInit {
  @ViewChild('content', { static: false }) private content;
  @ViewChild('search', null) public searchElement: ElementRef;
  addPartnerForm: FormGroup;
  coverImage;
  latitude;
  longitude;
  imageList = [];
  selectedFile: any;
  partnerImageUrl: string;
  coverImageUrl: string;
  googleLocation;
  partnerId: string;
  partnerData = [];
  partnerHeading: string = "Add Partner";
  timeData: string = "";
  timeArray = [];
  modelOptions: NgbModalOptions = {
    centered: true,
    size: 'md',
    backdrop: "static",
    keyboard: false
  };
  partnerCategoryList = [];

  constructor(private mapsAPILoader: MapsAPILoader,
    private router: Router, private modalService: NgbModal,
    private _snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private carnivalService: CarnivalService,
    private route: ActivatedRoute,
    private categoryMgmtService: CategoryMgmtService
  ) { }

  ngOnInit() {
    this.getPartnerCategories();
    this.initialiseForm()
    // this.SearchGoogle();

    this.partnerId = this.route.snapshot.params["id"];
    if (this.partnerId) {
      this.partnerHeading = "Edit Partner"

      this.fetchPartner();
    }
  }

  initialiseForm() {
    this.addPartnerForm = this.formBuilder.group({
      title: ["", Validators.required],
      description: ["", Validators.required],
      category: ["", Validators.required],
      email: ["", Validators.required],
      startDate: ["",],
      endDate: ["", Validators.required],
      location: ["", Validators.required],
      totalComments: [0],
      host: ['']
    });
  }

  fetchPartner() {
    this.categoryMgmtService.getPartnerById(this.partnerId).subscribe(res => {
      this.spinner.hide();
      if ((res["message"] = "Success")) {
        let partner = res["data"]["partner"];
        this.addPartnerForm.patchValue(partner);
        this.coverImageUrl = (partner["image"]);
        this.imageList = (partner["additionalImages"]);
      } 
      this.spinner.hide();
    }, err => { 
      this.spinner.hide();
    });
  }

  getPartnerCategories() {
    let query = '';

    this.spinner.show();
    this.categoryMgmtService.getPartnerCategoryListApi(query).subscribe(
      res => {
        this.spinner.hide();
        if ((res["message"] = "Success")) {
          this.partnerCategoryList = res["data"]["partnerCategoryList"];
        } else {
          this.partnerCategoryList = [];
        }
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
      }
    );
  }

  async addCoverPic(value: any) {
    const { value: file } = await Swal.fire({
      title: 'Select image',
      input: 'file',
      inputAttributes: {
        'accept': 'image/*',
        'aria-label': 'Upload Cover Image'
      }
    })
    if (file) {
      if (file['size'] < 5000) {
        Swal.fire("size is very less")
        return
      }
      this.selectedFile = file
      this.onFileChanged(file, "cover")
    }
  }

  async addImagesArray(value: any) {
    const { value: file } = await Swal.fire({
      title: 'Select image',
      input: 'file',
      inputAttributes: {
        'accept': 'image/*',
        'aria-label': 'Upload Images'
      }
    })
    if (file) {
      this.selectedFile = file;
      this.onFileChanged(file, 'images');
    }
  }

  onFileChanged(event, value) {
    this.spinner.show();
    this.carnivalService.uploadImage(this.selectedFile).subscribe(res => {
      if (res["message"] === "Success") {
        this.spinner.hide();
        if (value == 'cover') {
          this.coverImageUrl = res.data.url;
          this.selectedFile = null;
        } if (value == 'images') {
          this.partnerImageUrl = res.data.url;
          this.imageList.push(this.partnerImageUrl);
          this.selectedFile = null;
        }
      } else {
        Swal.fire({
          title: "Error",
          text: res.message
        });
      }
    }, err => {
    });
  }

  deleteImage(index: any) {
    this.imageList.splice(index, 1);
  }

  deleteCoverImage() {
    this.coverImageUrl = "";
  }

  partnerResponseId: any;
  submitForm() {
    // let locationData = {};
    // locationData["lat"] = this.latitude, locationData["lng"] = this.longitude;

    let reqObj = {
      title: this.addPartnerForm.value.title,
      description: this.addPartnerForm.value.description,
      category: this.addPartnerForm.value.category,
      additionalImages: this.imageList,
      image: this.coverImageUrl,
      location: this.addPartnerForm.value.location,
      startDate: this.addPartnerForm.value.startDate,
      endDate: this.addPartnerForm.value.endDate,
      host: this.addPartnerForm.value.host,
      email: this.addPartnerForm.value.email,
    };

    if (this.partnerId) {
      this.submitEditData(reqObj);
    } else {
      this.spinner.show();
      this.carnivalService.postPartner(reqObj).subscribe(

        data => {
          this.partnerResponseId = data.data._id
          console.log(this.partnerResponseId, "CARNIVALID")
          this.spinner.hide();
          if (data["message"] == "Success") {
            this._snackBar.open("Partner created successfully", "", {
              duration: 2000,
              horizontalPosition: "right",
              verticalPosition: "top",
              panelClass: ["success"],
            });
            this.initialiseForm();
            // setTimeout(() => {
            // this.modalService.open(this.content);
            // }, 1000);


            this.addPartnerForm.reset();
            this.imageList = [];
            this.coverImageUrl = "";
            this.router.navigate(['main/partner'])
          } else {
            Swal.fire("Error", data.data, "error");
          }
        },
        err => {
          this.spinner.hide();
        }
      );
    }
  }

  submitEditData(req: any) {
    this.spinner.show();
    console.log(req)
    req['partnerId'] = this.partnerId;
    this.carnivalService.putPartner(req).subscribe(
      data => {
        this.spinner.hide();
        if (data["message"] == "Success") {
          Swal.fire("Success", "Partner Edited Successfully!", "success");
          this.router.navigateByUrl('/main/partner');
          this.addPartnerForm.reset();
          this.imageList = [];
          this.coverImageUrl = "";
        } else {
          Swal.fire("Error", data.data, "error");
        }
      },
      err => {
        this.spinner.hide();
      }
    );
  }

  // SearchGoogle() {
  //   this.mapsAPILoader.load().then(
  //     () => {
  //       // let autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, { types: [] });
  //       // autocomplete.addListener("place_changed", () => {
  //       //   this.ngZone.run(() => {
  //       //     let place: google.maps.places.PlaceResult = autocomplete.getPlace();
  //       //     let location: google.maps.LatLngBounds
  //       //     if (place.geometry === undefined || place.geometry === null) {
  //       //       return;
  //       //     }
  //       //     //    set latitude, longitude and zoom
  //       //     this.latitude = place.geometry.location.lat();
  //       //     this.longitude = place.geometry.location.lng();
  //       //     this.googleLocation = place.formatted_address
  //       //     this.getTimeZone()
  //       //     //SET THE LAT AND LONG VALUE IN THE ADD RESTARURENT RESPONSE
  //       //   });
  //       // });
  //     }
  //   );
  // }

  getTimeZone() {
    let loc = this.latitude + "," + this.longitude;
    let targetDate = new Date() // Current date/time of user computer
    var timestamp = targetDate.getTime() / 1000 + targetDate.getTimezoneOffset() * 60 // Current UTC date/time expressed as seconds since midnight, January 1, 1970 UTC
    var apikey = AppConfig.apikey;
    var query = loc + '&timestamp=' + timestamp + '&key=' + apikey
    this.carnivalService.getTimeZone(query).subscribe(res => {
      if (res['status'] == "OK") {
        var timestampDate = 1403454068850,
          date = new Date(timestampDate);
        const utc = moment(date).tz(res.timeZoneId).format('Z')
        this.timeData = utc
      }
    })
  }

  // getCarnivalList() {
  //   let query = "partnerId=" + this.partnerId;
  //   this.spinner.show();
  //   this.carnivalService.getCarnivalListApi(query).subscribe(
  //     res => {
  //       this.spinner.hide();
  //       if ((res["message"] = "Success")) {
  //         this.partnerData = res["data"]["partnerList"][0];
  //         this.updateCarnivalData();
  //         console.log(this.partnerData)
  //       } else {
  //         this.partnerData = [];
  //       }
  //       this.spinner.hide();
  //     },
  //     err => {
  //       this.spinner.hide();
  //     }
  //   );
  // }

  updateCarnivalData() {
    let startdate = new Date((this.partnerData["startDate"] * 1000));
    let enddate = new Date((this.partnerData["endDate"] * 1000));
    this.addPartnerForm.controls["partnerLocation"].setValue(this.partnerData["locationName"]);
    this.addPartnerForm.controls["partnerTitle"].setValue(this.partnerData["title"]);
    this.addPartnerForm.controls["partnerStartDate"].setValue((startdate));
    this.addPartnerForm.controls["partnerEndDate"].setValue((enddate));
    this.addPartnerForm.controls["partnerDesc"].setValue(this.partnerData["description"]);
    this.addPartnerForm.controls["partnerDesc"].setValue(this.partnerData["description"]);
    this.latitude = (this.partnerData['location']["lat"]);
    this.timeData = this.partnerData['timezone']
    this.longitude = (this.partnerData['location']["lng"]);
    this.coverImageUrl = this.partnerData["coverImageUrl"];
    this.imageList = (this.partnerData["images"]);
  }

  navigateToContest() {
    this.router.navigate(["/main/contests-management/add-contest"], { queryParams: { partnerId: this.partnerResponseId }, preserveQueryParams: false });
    this.modalService.dismissAll();
  }

  closeModal() {
    this.modalService.dismissAll();
    this.router.navigate(["/main/partner"]);
  }
  // END ----- Update user status block
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

}