import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthComponent } from "./auth.component";
import { routing } from "./auth.routing";
import { AuthServices } from "./auth.service";
import { NgxSpinnerModule } from "ngx-spinner";
@NgModule({
    imports: [CommonModule, routing,NgxSpinnerModule],
    declarations: [AuthComponent],
    providers: [AuthServices]
  })
  export class AuthModule {}