import { Injectable } from "@angular/core";
import { NetworkService } from "../../common/network.service";

@Injectable()
export class UserMgmtService {
  constructor(private _networkService: NetworkService) {}

  getUserListApi(query) {
    return this._networkService.get("api/users/userList?" + query,null,null,"bearer");
  }
  updateUserStatus(user: any) {
    return this._networkService.put("api/users/", user, null, "bearer");
  }

  updateUserPointsApi(user: any) {
    return this._networkService.post("api/users/give/rewards", user, null, "bearer");
  }
  viewRewardListApi(query) {
    return this._networkService.get(
      "api/rewardHistory?userId=" + query,
      null,
      null,
      "bearer"
    );
  }
}
