import { Component, OnInit } from "@angular/core";
import { UserMgmtService } from "../user-management.service";
import { NgbModal, NgbModalRef, NgbModalOptions, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";

@Component({
  selector: "app-view-user",
  templateUrl: "./view-user.component.html",
  styleUrls: ["./view-user.component.scss"],
})
export class ViewUserComponent implements OnInit {
  // START - Update user variable
  userDetails;
  pointsType = "rewards";
  pointsVal:number = 0;
  query: string;
  statusFormSubmitted: boolean = false;
  statusForm: FormGroup;
  statusList: Array<object> = [
    { id: "active", name: "Active" },
    { id: "blocked", name: "Block" },
    { id: "suspended", name: "Suspend" }
  ];
  rewarList: Array<object> ;

  // END - Update user variable
  // filters
  searchStartDate: string;
  searchEndDate: string;
  one_day = 84600000;

  searchUserStatus: string = "all";
  searchBy: string = "all";
  searchText: string;
  // searchText: string = "";
  userList: any;
  statusPointList:Array<object> = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  pageLimit: number = 10;
  userSearchType: number = 0;
  minDate: any;
  maxDate: any;
  modelOptions: NgbModalOptions = {
    backdrop: "static",
    keyboard: false
  };
  modelOptions1: NgbModalOptions = {
    backdrop: "static",
    keyboard: false,
    size:"md"
  };
  closeResult: string;
  private modalRef: NgbModalRef;

  constructor (
    private userMgmtService: UserMgmtService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.getUserList();
  }

  queryParams() {
    let query = "";
    if (this.searchUserStatus && this.searchUserStatus != "all") query = query + "status=" + this.searchUserStatus+"&";
    if (this.searchBy && this.searchBy == "mobile" && this.searchText && this.searchText !== "") {
      query = query + "mobile=" + this.searchText.trim()+"&";
    }
    if (this.searchBy && this.searchBy == "email" && this.searchText && this.searchText !== "") {
      query = query + "email=" + this.searchText.trim()+"&";
    }
    if (this.searchStartDate && this.searchStartDate != "") {
      // convert to epoch time
      let startDate = new Date(this.searchStartDate);
      query = query + "startDate=" + Math.floor(startDate.getTime() / 1000)+"&";
    }
    if (this.searchEndDate && this.searchEndDate != "") {
      // convert to epoch time
      let endDate = new Date(this.searchEndDate);
      endDate.setDate(endDate.getDate() + 1);
      query = query + "endDate=" + Math.floor(endDate.getTime() / 1000);
    }
    return query;
  }

  // GET USERS (By Default ALL)
  getUserList() {
    let queryParam = "";
    var queryValue = this.queryParams();
    this.spinner.show();
    this.userMgmtService.getUserListApi(queryValue).subscribe(
      res => {
        this.spinner.hide();
        if ((res["message"] = "Success")) {
          this.userList = res["data"]["userList"];
        } else {
          this.userList = [];
        }
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
      }
    );
  }


  setName () {
    this.rewarList.forEach(element => {
      let Sting ="";
     let stingPoint = ""
      
        console.log("1")
        if(element['pointsCreditAt']) {
          var userData =element['pointsCreditAt'].split(/(?=[A-Z])/);
          var pointStatus =element['status'].split(/(?=[A-Z])/);
        } else if(element['pointsDebitAt']) {
          var userData =element['pointsDebitAt'].split(/(?=[A-Z])/);
          var pointStatus =element['status'].split(/(?=[A-Z])/);
        } else {
          userData = ""
          pointStatus = ""
        }
                
        

       var i;
       for (i = 0; i < userData.length; i++) {
        Sting += userData[i].charAt(0).toUpperCase(0) + userData[i].slice(1) + " ";
       }
       for (i = 0; i < pointStatus.length; i++) {
        stingPoint += pointStatus[i].charAt(0).toUpperCase(0) + pointStatus[i].slice(1) + " ";
       }
       console.log(userData);
        element['pointsType'] = Sting;
        element['pointStatus'] = stingPoint;
      })
      console.log(this.rewarList,"REWARD")
  }

  searchSelect(event) {
    this.searchText = "";
  }

  searchBusinessFilters() {
    this.getUserList();
  }
  
  onReset() {
    this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 2000);
    (this.searchText = ""),
      (this.searchUserStatus = ""),
      (this.searchBy = ""),
      (this.searchStartDate = ""),
      (this.searchEndDate = "");
    this.getUserList();
  }

  prepareUpdateStatusForm() {
    this.statusForm = this.formBuilder.group({
      newStatus: ["", [Validators.required]]
    });
  }

  updateUserStatusModal(row, content, btn) {
    btn && btn.parentElement && btn.parentElement.parentElement && btn.parentElement.parentElement.blur();
    this.userDetails = row;
    this.statusFormSubmitted = false;
    this.prepareUpdateStatusForm();
    this.modalRef = this.modalService.open(content, this.modelOptions);
    this.modalRef.result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  updateUserStatusModal1(row,type, content, btn) {
   this.spinner.show();
   let query = row.userId+'&status='+type
    this.userMgmtService.viewRewardListApi(query).subscribe(
      data => {
        this.spinner.hide();
        if ((data["message"] = "Success")) {
          this.spinner.hide();
          this.rewarList = data.data.rewardHistoryList;
          this.setName()
          btn && btn.parentElement && btn.parentElement.parentElement && btn.parentElement.parentElement.blur();
          this.modalRef = this.modalService.open(content, this.modelOptions1);
          this.modalRef.result.then(
            result => {
              this.closeResult = `Closed with: ${result}`;
            },
            reason => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
          ); 
      }
      err => {
        this.spinner.hide();
        this.modalRef.close();
      }}
    );
    
  }

  updateUserPointsModal(row, content, btn) {
    btn && btn.parentElement && btn.parentElement.parentElement && btn.parentElement.parentElement.blur();
    this.userDetails = row;
    this.modalRef = this.modalService.open(content, this.modelOptions);
    this.modalRef.result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  closeModal(){
    this.modalRef.close();
  }

  updateUserStatus(test) {
    this.statusFormSubmitted = true;
    if (this.statusForm.valid) {
      this.spinner.show();
      let finalObj = {
        userId: this.userDetails["userId"],
        status: this.statusForm.controls.newStatus.value
      };
      this.userMgmtService.updateUserStatus(finalObj).subscribe(
        data => {
          this.spinner.hide();
          if ((data["message"] = "Success")) {
            Swal.fire("Success", "User status updated successfully!", "success");
            this.modalRef.close();
            this.getUserList();
          } else {
            Swal.fire("Error", data.data, "error");
          }
        },
        err => {
          this.spinner.hide();
          Swal.fire("Error", err.error.data, "error");
          this.modalRef.close();
          this.getUserList();
        }
      );
    }
  }

  showImageModal(image: any) {
    if (image) {
      Swal.fire({
        title: "User Image",
        imageUrl: image,
        imageHeight: 250,
        imageWidth: 250,
        customClass: {
          title: "title-class11"
        }
      });
    }
  }

  updatePointsFunction() {
      this.spinner.show();
      let finalObj = {
        userId: this.userDetails["userId"],
        type: this.pointsType,
        points:this.pointsVal
      };
      this.userMgmtService.updateUserPointsApi(finalObj).subscribe(
        data => {
          this.spinner.hide();
          if ((data["message"] = "Success")) {
            Swal.fire("Success", "User points updated successfully!", "success");
            this.pointsVal = 0;
            this.pointsType = 'rewards'
            this.modalRef.close();
            this.getUserList();
          } else {
            Swal.fire("Error", data.data, "error");
          }
        },
        err => {
          this.spinner.hide();
          Swal.fire("Error", err.error.data, "error");
          this.modalRef.close();
          this.getUserList();
        }
      );
  }

  // END ----- Update user status block
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
