import { Injectable } from "@angular/core";
import { NetworkService } from "../../common/network.service";

@Injectable()
export class SettingService {
  constructor(private _networkService: NetworkService) { }


  saveSetting(body: any) {
    return this._networkService.post("api/webview", body, null, "bearer")
  }

  getSetting(query: any) {
    return this._networkService.get("api/setting/admin" + query, null, null, "bearer")

  }
}
